import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { FormSchema } from '../../../../../../../../../../../../libraries/form-schema/form-schema'
import { uatId } from '../../../../../../../../../../../../uat/uat-id.function'
import { BannerTextType } from '../../../../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { PhoneInput } from '../../../../../../../../../../../../ui-library/components/input-field/components/phone-input/phone-input.component'
import { PrimaryButton } from '../../../../../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { SecondaryButton } from '../../../../../../../../../../../../ui-library/components/secondary-button/secondary-button.component'
import { PhoneNumberFormSchema } from '../../../../../../../../../../../../shared/schema/phone-number/phone-number-schema.interface'
import { generatePhoneNumberFormSchema } from '../../../../../../../../../../../../shared/schema/phone-number/phone-number.schema'
import { ConsumerEditPhoneNumberStore } from '../../../../store/consumer-edit-phone-number.store'
import { ConsumerEditPhoneNumberState } from '../../../../types/consumer-edit-phone-number-state.interface'
import { ConsumerEditPhoneNumberSectionViewMessage } from '../../consumer-edit-phone-number-section-view.message'
import { getEditPhoneNumberErrorMessage } from './consumer-edit-phone-number-section-form.functions'
import { ConsumerEditPhoneNumberSectionFormMessage } from './consumer-edit-phone-number-section-form.message'
import { ConsumerEditPhoneNumberSectionFormProps } from './consumer-edit-phone-number-section-form.props'
import { ConsumerEditPhoneNumberSectionFormStyles } from './consumer-edit-phone-number-section-form.styles'
import { ConsumerEditPhoneNumberSectionFormUat } from './consumer-edit-phone-number-section-form.uat'
import { ConsumerProfileStore } from '../../../../../../../../../consumer-profile/store/consumer-profile.store'

export const ConsumerEditPhoneNumberSectionForm = (
  props: ConsumerEditPhoneNumberSectionFormProps
) => {
  const intl = useIntl()

  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] =
    useState<NonNullable<ConsumerEditPhoneNumberState['phoneNumber']>>('')

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const error: ConsumerEditPhoneNumberState['error'] = useSelector(
    ConsumerEditPhoneNumberStore.getError
  )

  const isEditSuccessful: ConsumerEditPhoneNumberState['isEditSuccessful'] =
    useSelector(ConsumerEditPhoneNumberStore.getIsEditSuccessful)
  const loading: ConsumerEditPhoneNumberState['loading'] = useSelector(
    ConsumerEditPhoneNumberStore.getLoading
  )

  useEffect(() => {
    if (phoneNumber.length < 10 && errorMessage) {
      setErrorMessage(undefined)
    }
    if (isEditSuccessful) {
      ConsumerProfileStore.setConsumerPhoneNumber(phoneNumber)
      props.setSectionToPhoneValidation()
    }
  }, [isEditSuccessful, phoneNumber, props])

  useEffect(() => {
    if (error) {
      setErrorMessage(intl.formatMessage(getEditPhoneNumberErrorMessage(error)))
    }
  }, [error])

  const formSchema: FormSchema<PhoneNumberFormSchema> =
    generatePhoneNumberFormSchema()

  const onPhoneNumberChange = (number: string) => {
    setPhoneNumber(number)
    const isFormValid: boolean = formSchema.isValidSync({
      phoneNumber: number
    })
    setIsFormValid(isFormValid)
  }

  const onSubmit = () =>
    ConsumerEditPhoneNumberStore.editPhoneNumber(phoneNumber)

  const onCancel = () => props.setSectionToPhoneValidation()

  return (
    <>
      <div
        className={ConsumerEditPhoneNumberSectionFormStyles.formContainer(
          Boolean(errorMessage)
        )}
      >
        <PhoneInput
          uat={uatId(ConsumerEditPhoneNumberSectionFormUat.field)}
          name={'phoneNumber'}
          label={intl.formatMessage(
            ConsumerEditPhoneNumberSectionFormMessage.phoneNumberLabel
          )}
          value={phoneNumber}
          onChange={onPhoneNumberChange}
        />
      </div>

      <BannerText
        uat={uatId(ConsumerEditPhoneNumberSectionFormUat.errorMessage)}
        cssContainerClasses={
          ConsumerEditPhoneNumberSectionFormStyles.errorBanner
        }
        type={BannerTextType.DANGER}
        showIf={Boolean(errorMessage)}
        text={errorMessage}
      />

      <div className={ConsumerEditPhoneNumberSectionFormStyles.buttonContainer}>
        <PrimaryButton
          uat={uatId(ConsumerEditPhoneNumberSectionFormUat.submitButton)}
          cssContainerClasses={
            ConsumerEditPhoneNumberSectionFormStyles.primaryButton
          }
          loading={loading}
          disabled={!isFormValid}
          onClick={onSubmit}
        >
          <FormattedMessage
            {...ConsumerEditPhoneNumberSectionViewMessage.primaryButtonText}
          />
        </PrimaryButton>

        <SecondaryButton
          onClick={onCancel}
          uat={uatId(ConsumerEditPhoneNumberSectionFormUat.cancelButton)}
          cssContainerClasses={ConsumerEditPhoneNumberSectionFormStyles.cancelButton}
        >
          <FormattedMessage
            {...ConsumerEditPhoneNumberSectionViewMessage.cancelButtonText}
          />
        </SecondaryButton>
      </div>
	  <div className={ConsumerEditPhoneNumberSectionFormStyles.disclaimer}>
	  <FormattedMessage
            {...ConsumerEditPhoneNumberSectionViewMessage.disclaimer}
          />
		  </div>
    </>
  )
}
