import { ConsumerProfilePostSubmissionConfig } from '../../../../../../../domain/consumer/consumer-profile/types/consumer-profile-post-submission-config/consumer-post-submission-config.interface'
import { ConsumerProfilePostSubmissionConfigPage } from '../../../../../../../domain/consumer/consumer-profile/types/consumer-profile-post-submission-config/types/consumer-profile-post-submission-config-pages.enum'
import { ConsumerProfilePostSubmissionConfigRulesVariant } from '../../../../../../../domain/consumer/consumer-profile/types/consumer-profile-post-submission-config/types/consumer-profile-post-submission-config-rules-variant.enum'
import { ConsumerProfilePostSubmissionConfigRules } from '../../../../../../../domain/consumer/consumer-profile/types/consumer-profile-post-submission-config/types/consumer-profile-post-submission-config-rules.interface'

export const getPostSubmissionConfigApprovedRules = (postSubmissionConfig: ConsumerProfilePostSubmissionConfig): ConsumerProfilePostSubmissionConfigRules | undefined => {
  return postSubmissionConfig?.pages.find(page => page.type === ConsumerProfilePostSubmissionConfigPage.Approval)?.rules
}

export const getPostSubmissionConfigApprovedOffersVariant = (postSubmissionConfig: ConsumerProfilePostSubmissionConfig): ConsumerProfilePostSubmissionConfigRulesVariant | undefined => {
  return postSubmissionConfig?.pages.find(page => page.type === ConsumerProfilePostSubmissionConfigPage.Approval)?.variant
}