import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component';
import { OfferUnsubscribeErrorView } from './offer-unsubscribe-error/offer-unsubscribe-error-view.component';

export const OfferUnsubscribeError = () => {
  return  (
    <MainLayout
      showDefaultHeader
      hideNavBar
      hideHeader
      centerAlignDisclaimer
      hideDisclaimer
    >
      <OfferUnsubscribeErrorView />
    </MainLayout>
  );
}
