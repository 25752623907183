export enum ConsumerRoute {
  RouteResolver = '/',
  SelectAssetType = '/vendor/:vendorId/select-asset-type',
  LoadingProduct = '/vendor/:vendorId/product/:sku',
  GenericVendorPage = '/vendor/:vendorId',
  Authenticate = '/consumer/authenticate/:authCode',
  SubmitLoading = '/vendor/:vendorId/consumer/submit/loading',
  SubmitApproved = '/vendor/:vendorId/consumer/submit/approved/:variant',
  SubmitNoOfferAvailable = '/vendor/:vendorId/consumer/submit/no-offer-available',
  SubmitError = '/vendor/:vendorId/consumer/submit/error',
  SubmitOffer = '/vendor/:vendorId/consumer/submit/offers',
  SelectMerchant = '/vendor/:vendorId/select-merchant',
  SelectMerchantSuccess = '/vendor/:vendorId/select-merchant/success',
  OfferUnsubscribe = '/offer/unsubscribe',
  OfferUnsubscribeLoading = '/offer/unsubscribe/loading',
  OfferUnsubscribeSuccess = '/offer/unsubscribe/success',
  OfferUnsubscribeError = '/offer/unsubscribe/error',
}
