export const OfferUnsubscribeViewMessages = {
  unsubscribeMessage: {
    id: 'Pages.OfferUnsubscribe.View.unsubscribeMessage',
    description: 'Offer Unsubscribe Message'
  },
  buttonText: {
    id: 'Pages.OfferUnsubscribe.View.buttonText',
    description: 'Offer Unsubscribe Button Text'
  },
}
