import { CSSProperties } from 'react'

const overlayStyle: Record<string, CSSProperties> = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,0.8)',
  zIndex: 10
} as Record<string, CSSProperties>

const contentStyle: Record<string, CSSProperties> = {
  position: 'relative',
  overflow: 'auto',
  maxHeight: 'calc(100vh - 5rem)',
  WebkitOverflowScrolling: 'touch'
} as Record<string, CSSProperties>

export const ModalLibraryDesktopStyles: Record<string, CSSProperties> = {
  overlay: overlayStyle,
  content: contentStyle
}

export const SmallModalDesktopStyles: Record<string, CSSProperties> = {
  ...ModalLibraryDesktopStyles,
  content: {
    ...ModalLibraryDesktopStyles.content,
    maxWidth: '350px'
  }
}

export const ModalLibraryMobileStyles: Record<string, CSSProperties> = {
  overlay: overlayStyle,
  content: {
    ...contentStyle,
    marginTop: '5vh',
    marginBottom: '5vh',
    maxHeight: 'unset',
    height: '90vh',
    inset: 0
  }
}

export const ModalLibraryClassNames = {
  body: 'overflow-hidden'
}
