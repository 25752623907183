import { Observable } from 'rxjs'
import { Answer } from '../../../domain/consumer/security-questions/types/questions/answer.interface'
import { QuestionType } from '../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'
import { ConsumerSessionTokenDto } from '../dto/consumer-session-token/consumer-session-token.dto'

export class SecurityQuestionsService extends BffService {
  public fetchSecurityQuestions(authCode: string): Observable<QuestionType[]> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/consumer-profile/authorize/${authCode}`
    })
  }

  public submitSecurityQuestionsAnswers(
    answers: Answer[],
    authCode: string,
    trackerId: string | null
  ): FetchResponse<ConsumerSessionTokenDto> {
    const trackerQueryParam = trackerId !== null ? `trackerId=${trackerId}` : ''; 
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/consumer-profile/authorize/${authCode}/verify?${trackerQueryParam}`,
      body: {
        answers
      }
    })
  }
}
