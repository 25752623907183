export const ConsumerPostSubmissionApprovedViewUat = {
  title: 'workflow.postSubmissionApproved.title',
  approvedAmount: 'workflow.postSubmissionApproved.approvedAmount',
  approvedAmountMessage:
    'workflow.postSubmissionApproved.approvedAmountMessage',
  selectMerchantConfirmButton:
    'workflow.postSubmissionApproved.selectMerchantConfirmButton',
  redirectApplicantPortalButton:
    'workflow.postSubmissionApproved.redirectApplicantPortal',
  aboutMessage: 'workflow.postSubmissionApproved.aboutMessage',
  applicantPortalModal: 'workflow.postSubmissionApproved.applicantPortalModal',
}
