import { createOpportunitySubscriptionState } from '../types/opportunity-subscription-state.functions'
import { OpportunitySubscriptionState } from '../types/opportunity-subscription-state.interface'
import { OpportunitySubscriptionAction, OpportunitySubscriptionActionType } from './opportunity-subscription.actions'

export function opportunitySubscriptionReducer(
  state: OpportunitySubscriptionState = createOpportunitySubscriptionState(),
  action: OpportunitySubscriptionActionType,
): OpportunitySubscriptionState {
  switch (action.type) {
    case OpportunitySubscriptionAction.UnsubscribeEmail:
      return {
        ...state,
        isLoading: true,
        unsubscribeSuccess: null,
      }
    case OpportunitySubscriptionAction.UnsubscribeEmailSuccess:
      return {
        ...state,
        isLoading: false,
        unsubscribeSuccess: true,
      }
    case OpportunitySubscriptionAction.UnsubscribeEmailFailure:
      return {
        ...state,
        isLoading: false,
        unsubscribeError: action.payload,
        unsubscribeSuccess: false,
      }
    default:
      return state
  }
}
