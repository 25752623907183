import { useSelector } from 'react-redux'
import { LoaderWithMessages } from '../../../../../../shared/components/loader-with-messages/loader-with-messages.component'
import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { SpinnerIconSize } from '../../../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { uatId } from '../../../../../../uat/uat-id.function'
import { OpportunitySubscriptionStore } from '../../store/opportunity-subscription.store'
import { OfferUnsubscribeLoadingSpinnerMessages } from './offer-unsubscribe-loading-spinner.messages'
import { OfferUnsubscribeLoadingSpinnerStyles } from './offer-unsubscribe-loading-spinner.styles'
import { OfferUnsubscribeLoadingSpinnerUat } from './offer-unsubscribe-loading-spinner.uat'
import { useEffect } from 'react'
import { ConsumerRoute } from '../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { useHistory } from 'react-router'
import { getLoadingTimeoutInMillis } from './offer-unsubscribe-loading-spinner.functions'

export const OfferUnsubscribeLoadingSpinner = () => {
  const history = useHistory()
  const unsubscribeSuccess = useSelector(OpportunitySubscriptionStore.getUnsubscribeSuccess)

  useEffect(() => {
    if (unsubscribeSuccess === true) {
      history.push(ConsumerRoute.OfferUnsubscribeSuccess)
    } else if (unsubscribeSuccess === false) {
      history.push(ConsumerRoute.OfferUnsubscribeError)
    }
  }, [unsubscribeSuccess])

  return (
    <MainLayout hideDisclaimer centerAlignDisclaimer>
      <div
        className={OfferUnsubscribeLoadingSpinnerStyles.container}
        {...uatId(OfferUnsubscribeLoadingSpinnerUat.spinner)}
      >
        <LoaderWithMessages
          timeoutPeriodInMillis={getLoadingTimeoutInMillis()}
          spinnerSize={SpinnerIconSize.LARGE}
          messages={OfferUnsubscribeLoadingSpinnerMessages}
        />
      </div>
    </MainLayout>
  )
}
