import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { withHideIf } from '../../../../../../../../ui-library/helpers/with-hide-if/with-hide-if.component'
import { PreQualifierAssetTypeSelectionStore } from '../../../../../pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.store'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { ConsumerWorkflowStepsStore } from '../../../consumer-workflow-steps/store/consumer-workflow-steps.store'
import { ConsumerPhoneNumberStepStore } from '../../store/consumer-phone-number-step.store'
import { ConsumerPhoneNumberStepState } from '../../types/consumer-phone-number-step-state.interface'
import { ConsumerPhoneNumberForm } from '../consumer-phone-number-form/consumer-phone-number-form.component'
import { ConsumerPhoneNumberViewMessages } from './consumer-phone-number-view.message'
import { ConsumerPhoneNumberViewProps } from './consumer-phone-number-view.props'
import { ConsumerPhoneNumberViewUat } from './consumer-phone-number-view.uat'
import { SettingsStore } from '../../../../../../../app/modules/settings/store/settings.store'
import { PreQualifierLoadingProductStore } from '../../../../../pre-qualifier-loading-product/store/pre-qualifier-loading-product.store'
import { CreateConsumerProfileFormFactory } from '../../types/create-consumer-profile-form.factory'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { toBoolean } from '../../../../../../../../shared/helpers/to-boolean/to-boolean-formatter'

export const ConsumerPhoneNumberView = withHideIf(
  (props: ConsumerPhoneNumberViewProps) => {
    const intl = useIntl();
    const locale = useSelector(SettingsStore.getLocaleSetting)
    const activeWorkflowStep = useSelector(
      ConsumerWorkflowStepsStore.getActiveWorkflowStep
    )
    const consumerProfileState = useSelector(ConsumerProfileStore.getConsumerProfileState);

    const productDetails = useSelector(
      PreQualifierLoadingProductStore.getProductDetails
    )
    const selectedAssetType = useSelector(
      PreQualifierAssetTypeSelectionStore.getSelectedAssetType
    )

    const [phoneNumber, setPhoneNumber] = useState(
      useSelector(ConsumerProfileStore.getConsumerPhoneNumber)
    )

    const error: ConsumerPhoneNumberStepState['error'] = useSelector(
      ConsumerPhoneNumberStepStore.getError
    )

    const isWorkflowStepComplete = useSelector(
      ConsumerPhoneNumberStepStore.getIsWorkflowStepComplete
    )

    const loading = useSelector(ConsumerPhoneNumberStepStore.getLoading)

    useEffect(() => {
      if (isWorkflowStepComplete) {
        ConsumerProfileStore.setConsumerPhoneNumber(phoneNumber)
        props.onComplete()
      }
    }, [phoneNumber, isWorkflowStepComplete, props])

    const onSubmit = (
      phoneNumber: ConsumerProfileState['phoneNumber']
    ): void => {
      setPhoneNumber(phoneNumber)

      if (productDetails) {
        ConsumerProfileStore.createConsumerProfileWithAsset(
          CreateConsumerProfileFormFactory.createWithAssetForm(consumerProfileState, phoneNumber, locale, productDetails.id)
        )
      } else if (selectedAssetType) {
        ConsumerProfileStore.createConsumerProfile(
          CreateConsumerProfileFormFactory.createForm(consumerProfileState, phoneNumber, locale, selectedAssetType.id)
        )
      }
    }

    return (
      <>
        <PrimaryHeader
          uat={uatId(ConsumerPhoneNumberViewUat.header)}
          showBackIcon={toBoolean(activeWorkflowStep?.rules.showBackButton)}
          goBack={props.goBack}
        />
        <ConsumerProfileAssetImageContainer
          titleMessage={intl.formatMessage(ConsumerPhoneNumberViewMessages.paragraph)}
          warningMessage={intl.formatMessage(ConsumerPhoneNumberViewMessages.warning)}
          disclaimerMessage={intl.formatMessage(ConsumerPhoneNumberViewMessages.disclaimer)}
          subTitleMessage={intl.formatMessage(ConsumerPhoneNumberViewMessages.verify)}
          subTitleUat={uatId(ConsumerPhoneNumberViewUat.infoMessage)}
          warningUat={uatId(ConsumerPhoneNumberViewUat.warningMessage)}
          disclaimerUat={uatId(ConsumerPhoneNumberViewUat.disclaimerMessage)}
          noHorizontalPadding
          showFooter
        >
          <ConsumerPhoneNumberForm
            error={error}
            phoneNumber={useSelector(ConsumerProfileStore.getConsumerPhoneNumber)}
            loading={loading}
            onSubmit={onSubmit}
          />
        </ConsumerProfileAssetImageContainer>
      </>
    )
  }
)
