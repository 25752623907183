import { useIntl } from 'react-intl'
import { Paragraph } from '../../../../../../../ui-library/components/paragraph/paragraph.component'
import { uatId } from '../../../../../../../uat/uat-id.function'
import { OfferUnsubscribeErrorViewStyles } from './offer-unsubscribe-error-view.styles'
import { OfferUnsubscribeErrorViewUat } from './offer-unsubscribe-error-view.uat'
import { OfferUnsubscribeErrorViewMessages } from './offer-unsubscribe-error-view.messages'

export const OfferUnsubscribeErrorView = () => {
  const intl = useIntl()

  return (
    <Paragraph className={OfferUnsubscribeErrorViewStyles.message} uat={uatId(OfferUnsubscribeErrorViewUat.message)}>
      {intl.formatMessage(OfferUnsubscribeErrorViewMessages.errorMessage)}
    </Paragraph>
  )
}
