import { CloseIcon } from '../../../../shared/icons/close/close.icon'
import { uatId } from '../../../../uat/uat-id.function'
import { ModalCloseButtonProps } from './modal-close-button.props'
import { ModalCloseButtonStyles } from './modal-close-button.styles'
import { ModalCloseButtonUat } from './modal-close-button.uat'

export const ModalCloseButton = (props: ModalCloseButtonProps) => {
  return (
    <div
      role="button"
      className={ModalCloseButtonStyles.container}
      onClick={props.onClick}
      {...uatId(ModalCloseButtonUat.closeButton)}
    >
      <CloseIcon className="cursor-pointer" />
    </div>
  )
}
