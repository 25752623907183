import { useIntl } from 'react-intl'
import { Paragraph } from '../../../../../../../ui-library/components/paragraph/paragraph.component'
import { OfferUnsubscribeViewSuccessUat } from './offer-unsubscribe-success-view.uat'
import { uatId } from '../../../../../../../uat/uat-id.function'
import { OfferUnsubscribeSuccessViewStyles } from './offer-unsubscribe-success-view.styles'
import { OfferUnsubscribeSuccessViewMessages } from './offer-unsubscribe-success-view.messages'

export const OfferUnsubscribeSuccessView = () => {
  const intl = useIntl()

  return (
    <Paragraph className={OfferUnsubscribeSuccessViewStyles.message} uat={uatId(OfferUnsubscribeViewSuccessUat.message)}>
      {intl.formatMessage(OfferUnsubscribeSuccessViewMessages.successMessage)}
    </Paragraph>
  )
}
