import { ModalLibrary } from '../../../libraries/modal/modal-library.component'
import { ModalProps } from './modal.props'

export const Modal = (props: ModalProps) => {
  return (
    <ModalLibrary
      className={props.className}
      open={props.open}
      smallModal={props.smallModal}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onClose={props.onClose}
      uat={props.uat}
      showCloseButton={props.showCloseButton}
    >
      {props.children}
    </ModalLibrary>
  )
}
