import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component';
import { OfferUnsubscribeView } from './offer-unsubscribe-view/offer-unsubscribe-view.component';

export const OfferUnsubscribe = () => {
  return  (
    <MainLayout
      showDefaultHeader
      hideNavBar
      hideHeader
      centerAlignDisclaimer
      hideDisclaimer
    >
      <OfferUnsubscribeView />
    </MainLayout>
  );
}
