export const ConsumerEditPhoneNumberSectionViewMessage = {
  banner: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.View.banner',
    description: 'No worries! please enter your phone number'
  },
  cancelButtonText: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.View.cancelButtonText',
    description: 'Never Mind'
  },
  inputLabel: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.View.inputLabel',
    description: 'My New Phone Number is'
  },
  primaryButtonText: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.View.primaryButtonText',
    description: 'Send Code'
  },
  disclaimer: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.View.disclaimer',
    description: 'Std msg & data rates may apply.'
  },
}
