export const ConsumerPhoneValidationViewMessage = {
  header: {
    id: 'Pages.ConsumerPhoneValidation.View.header',
    description: 'Getting Pre-Qualified'
  },
  title: {
    id: 'Pages.ConsumerPhoneValidation.View.title',
    description: 'Received your sms code'
  },
  verifyPhoneSubTitle: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.View.title',
    description: 'Thank you, here is your 6-digit sms code'
  },
  editPhoneNumberTitle: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.View.banner',
    description: 'No worries! please enter your phone number'
  },
  disclaimer: {
    id: 'Pages.ConsumerPhoneNumber.View.disclaimer',
    description: 'Std msg & data rates may apply'
  },
}
