import { Observable } from 'rxjs'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { BffService } from '../bff.service'

export class OfferUnsubscribeService extends BffService {
  public unsubscribeEmail(email: string): Observable<void> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/opportunity-subscription-contact-list/unsubscribe/email`,
      body: {
        email
      }
    })
  }
}
