import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component';
import { OfferUnsubscribeSuccessView } from './offer-unsubscribe-success-view/offer-unsubscribe-success-view.component';

export const OfferUnsubscribeSuccess = () => {
  return  (
    <MainLayout
      showDefaultHeader
      hideNavBar
      hideHeader
      centerAlignDisclaimer
      hideDisclaimer
    >
      <OfferUnsubscribeSuccessView />
    </MainLayout>
  );
}
