import { Action } from 'redux'
import { Epic } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { SecurityQuestionsService } from '../../../../../../../api/bff/services/security-questions.service'
import { Answer } from '../../../../../../../domain/consumer/security-questions/types/questions/answer.interface'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { SecurityQuestionsActions as actions } from './security-questions.actions'

const fetchSecurityQuestionsEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.fetchSecurityQuestions)),
    map((action) => action.payload),
    mergeMap((authCode) =>
      new SecurityQuestionsService().fetchSecurityQuestions(authCode).pipe(
        map((questions) => actions.fetchSecurityQuestionsSuccess(questions)),
        catchError((error) => of(actions.fetchSecurityQuestionsError(error)))
      )
    )
  )

const answerSecurityQuestionsEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(actions.answerSecurityQuestions)),
    map((action) => [action.payload.answers, action.payload.authCode, action.payload.trackerId]),
    mergeMap(([answers, authCode, trackerId]) =>
      new SecurityQuestionsService()
        .submitSecurityQuestionsAnswers(answers as Answer[], authCode as string, trackerId as string | null)
        .pipe(
          map((result) => actions.answerSecurityQuestionsSuccess(result)),
          catchError((error) => of(actions.answerSecurityQuestionsError(error)))
        )
    )
  )

export const securityQuestionsEpics = [
  fetchSecurityQuestionsEpic,
  answerSecurityQuestionsEpic
]
