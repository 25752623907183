import { ActionType, createAction } from 'typesafe-actions';
import { HttpError } from '../../../../../shared/types/http-error.interface';

export enum OpportunitySubscriptionAction {
  UnsubscribeEmail = 'OfferUnsubscribe/UnsubscribeEmail',
  UnsubscribeEmailSuccess = 'OfferUnsubscribe/UnsubscribeEmailSuccess',
  UnsubscribeEmailFailure = 'OfferUnsubscribe/UnsubscribeEmailFailure',
}

const unsubscribeEmail = createAction(
  OpportunitySubscriptionAction.UnsubscribeEmail,
  action => (email: string) => action(email),
)

const unsubscribeEmailSuccess = createAction(
  OpportunitySubscriptionAction.UnsubscribeEmailSuccess,
  action => () => action(),
)

const unsubscribeEmailFailure = createAction(
  OpportunitySubscriptionAction.UnsubscribeEmailFailure,
  action => (error: HttpError) => action(error),
)

export const OpportunitySubscriptionActions = {
  unsubscribeEmail,
  unsubscribeEmailSuccess,
  unsubscribeEmailFailure,
};

export type OpportunitySubscriptionActionType = ActionType<typeof OpportunitySubscriptionActions>
