import { Reducer } from 'redux'
import { ConsumerProfileActionType } from '../modules/consumer-profile/store/consumer-profile.actions'
import { consumerProfileReducer } from '../modules/consumer-profile/store/consumer-profile.reducer'
import { ConsumerWorkflowActionType } from '../modules/consumer-workflow/store/consumer-workflow.actions'
import { consumerWorkflowReducer } from '../modules/consumer-workflow/store/consumer-workflow.reducer'
import { ConsumerSessionActionType } from '../modules/consumer-session/store/consumer-session.actions'
import { consumerSessionReducer } from '../modules/consumer-session/store/consumer-session.reducer'
import { createConsumerState } from '../types/consumer-state.functions'
import { ConsumerState } from '../types/consumer-state.interface'
import { ConsumerActionType } from './consumer.actions'
import { merchantSelectionReducer } from '../modules/merchant-selection/store/merchant-selection.reducer'
import { preQualifierAssetTypeSelectionReducer } from '../modules/pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.reducer'
import { PreQualifierAssetTypeSelectionActionType } from '../modules/pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.actions'
import { MerchantSelectionActionType } from '../modules/merchant-selection/store/merchant-selection.actions'
import { securityQuestionsReducer } from '../modules/consumer-authentication/modules/security-questions/store/security-questions.reducer'
import { SecurityQuestionsActionsType } from '../modules/consumer-authentication/modules/security-questions/store/security-questions.actions'
import { consumerPostSubmissionReducer } from '../modules/consumer-post-submission/store/consumer-post-submission.reducer'
import { ConsumerPostSubmissionActionType } from '../modules/consumer-post-submission/store/consumer-post-submission.actions'
import { PreQualifierLoadingProductActionType } from '../modules/pre-qualifier-loading-product/store/pre-qualifier-loading-product.actions'
import { preQualifierLoadingProductReducer } from '../modules/pre-qualifier-loading-product/store/pre-qualifier-loading-product.reducer'
import { AssetSubtypeReducer } from '../modules/asset-subtype-selection/store/asset-subtype-selection.reducer'
import { AssetSubtypeActionType } from '../modules/asset-subtype-selection/store/asset-subtype-selection.actions'
import { geoLocationReducer } from '../modules/geo-location/store/geo-location.reducer'
import { GeoLocationActionType } from '../modules/geo-location/store/geo-location.actions'
import { OpportunitySubscriptionActionType } from '../modules/opportunity-subscription/store/opportunity-subscription.actions'
import { opportunitySubscriptionReducer } from '../modules/opportunity-subscription/store/opportunity-subscription.reducer'

export const consumerReducer: Reducer<ConsumerState, ConsumerActionType> = (
  state: ConsumerState = createConsumerState(),
  action: ConsumerActionType
): ConsumerState => {
  return {
    ...state,
    consumerWorkflowState: consumerWorkflowReducer(
      state.consumerWorkflowState,
      action as ConsumerWorkflowActionType
    ),
    consumerProfileState: consumerProfileReducer(
      state.consumerProfileState,
      action as ConsumerProfileActionType
    ),
    consumerSessionState: consumerSessionReducer(
      state.consumerSessionState,
      action as ConsumerSessionActionType
    ),
    securityQuestionsState: securityQuestionsReducer(
      state.securityQuestionsState,
      action as SecurityQuestionsActionsType
    ),
    merchantSelectionState: merchantSelectionReducer(
      state.merchantSelectionState,
      action as MerchantSelectionActionType
    ),
    assetSubtypeState: AssetSubtypeReducer(
      state.assetSubtypeState,
      action as AssetSubtypeActionType
    ),
    preQualifierAssetTypeSelectionState: preQualifierAssetTypeSelectionReducer(
      state.preQualifierAssetTypeSelectionState,
      action as PreQualifierAssetTypeSelectionActionType
    ),
    preQualifierLoadingProductState: preQualifierLoadingProductReducer(
      state.preQualifierLoadingProductState,
      action as PreQualifierLoadingProductActionType
    ),
    consumerPostSubmissionState: consumerPostSubmissionReducer(
      state.consumerPostSubmissionState,
      action as ConsumerPostSubmissionActionType
    ),
    geoLocationState: geoLocationReducer(
      state.geoLocationState,
      action as GeoLocationActionType
    ),
    offerUnsubscribeState: opportunitySubscriptionReducer(
      state.offerUnsubscribeState,
      action as OpportunitySubscriptionActionType
    )
  }
}
