import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../store/types/root-state/root-state.interface';
import { OpportunitySubscriptionActions } from './opportunity-subscription.actions';
import { OfferUnsubscribeService } from '../../../../../api/bff/services/offer-unsubscribe-service';

const unsubscribeEmailEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(OpportunitySubscriptionActions.unsubscribeEmail)),
    map(action => action.payload),
    mergeMap(email =>
      new OfferUnsubscribeService().unsubscribeEmail(email).pipe(
        map(() => OpportunitySubscriptionActions.unsubscribeEmailSuccess()),
        catchError(error => of(OpportunitySubscriptionActions.unsubscribeEmailFailure(error))),
      ),
    ),
  );

export const opportunitySubscriptionEpics = [unsubscribeEmailEpic];
