import { useHistory, useLocation } from 'react-router';
import { ConsumerRouteSearchParam } from '../../../../../../../domain/consumer/consumer-routes/consumer-route-search-param.enum';
import { PrimaryButton } from '../../../../../../../ui-library/components/primary-button/primary-button.component';
import { Paragraph } from '../../../../../../../ui-library/components/paragraph/paragraph.component';
import { OfferUnsubscribeViewStyles } from './offer-unsubscribe-view.styles';
import { useIntl } from 'react-intl';
import { OfferUnsubscribeViewMessages } from './offer-unsubscribe-view.messages';
import { uatId } from '../../../../../../../uat/uat-id.function';
import { OfferUnsubscribeViewUat } from './offer-unsubscribe-view.uat';
import { OpportunitySubscriptionStore } from '../../../store/opportunity-subscription.store';
import { ConsumerRoute } from '../../../../../../../domain/consumer/consumer-routes/consumer-route.enum';

export const OfferUnsubscribeView = () => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const email = new URLSearchParams(location.search).get(ConsumerRouteSearchParam.Email)

  const handleClick = (): void => {
    if (email) {
      OpportunitySubscriptionStore.unsubscribeEmail(email)
      history.push(ConsumerRoute.OfferUnsubscribeLoading)
    }
  }

  return  (
    <>
      <Paragraph className={OfferUnsubscribeViewStyles.unsubscribeMessage} uat={uatId(OfferUnsubscribeViewUat.message)}>
        {intl.formatMessage(OfferUnsubscribeViewMessages.unsubscribeMessage)}
      </Paragraph>
      <PrimaryButton
        onClick={handleClick}
        loading={false}
        uat={uatId(OfferUnsubscribeViewUat.button)}
      >
        {intl.formatMessage(OfferUnsubscribeViewMessages.buttonText)}
      </PrimaryButton>
    </>
  );
}
