export const ConsumerProfileAssetImageContainerStyles = {
    wrapper: (noHorizontalPadding: boolean) => `flex w-full max-w-6xl gap-x-3 flex-col-reverse items-center 1xl:flex-row 1xl:items-stretch  ${
        !noHorizontalPadding ? 'px-8 sm:px-16' : ''
    }`,
    container: 'flex flex-col flex-1 items-center 1xl:items-baseline',
    titleContainer: 'flex items-center text-skyblue900 text-center 1xl:text-left',
    title: 'hidden text-3xl font-montserrat font-semibold text-goEasyBlue400 sm:block',
    subtitleContainer: 'flex flex-col my-3 gap-3',
    subTitle: 'my-0 text-xl font-regular text-goEasyGray900 text-center sm:text-left',
    disclaimer: 'flex w-full justify-center text-gray text-16px mb-5 font-bold'
}