import { defineMessages } from 'react-intl';

export const OfferUnsubscribeLoadingSpinnerMessages = [
  defineMessages({
    message: {
      id: 'Pages.OfferUnsubscribe.Loading.View.label',
      description: 'Offer notification unsubscribe loading message'
    },
  }),
]
