import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { Answer } from '../../../../../../../domain/consumer/security-questions/types/questions/answer.interface'
import { QuestionType } from '../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { SecurityQuestionsState } from '../types/security-questions-state/security-questions-state.interface'
import { SecurityQuestionsActions as actions } from './security-questions.actions'

const getSecurityQuestionsState = (rootState: RootState) =>
  rootState.consumerState.securityQuestionsState

const getSecurityQuestions: Selector<RootState, QuestionType[] | null> =
  createSelector(
    getSecurityQuestionsState,
    (state: SecurityQuestionsState) => state.securityQuestions
  )

const getError: Selector<RootState, HttpError | undefined> = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.error
)

const getNumberOfAttempts: Selector<RootState, number | null> = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.attemptsRemaining
)

const getIsLoading: Selector<RootState, boolean> = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.isLoading
)

const getIsSubmittingSecurityQuestions: Selector<RootState, boolean> =
  createSelector(
    getSecurityQuestionsState,
    (state: SecurityQuestionsState) => state.submittingSecurityQuestions
  )

function fetchSecurityQuestions(authCode: string) {
  RootStore.dispatch(actions.fetchSecurityQuestions(authCode))
}

function answerSecurityQuestions(answers: Answer[], authCode: string,trackerId:string|null) {
  RootStore.dispatch(actions.answerSecurityQuestions(answers, authCode,trackerId))
}

export const SecurityQuestionsStore = {
  fetchSecurityQuestions,
  getSecurityQuestionsState,
  getSecurityQuestions,
  getError,
  getIsLoading,
  getIsSubmittingSecurityQuestions,
  answerSecurityQuestions,
  getNumberOfAttempts
}
