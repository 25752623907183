import { createConsumerProfileState } from '../modules/consumer-profile/types/consumer-profile-state.functions'
import { createConsumerWorkflowState } from '../modules/consumer-workflow/types/consumer-workflow-state/consumer-workflow-state.functions'
import { createConsumerSessionState } from '../modules/consumer-session/types/consumer-session-state.functions'
import { ConsumerState } from './consumer-state.interface'
import { createPreQualifierAssetTypeSelectionState } from '../modules/pre-qualifier-asset-type-selection/types/pre-qualifier-asset-type-selection-state.functions'
import { createSecurityQuestionsState } from '../modules/consumer-authentication/modules/security-questions/types/security-questions-state/security-questions-state.functions'
import { createMerchantSelectionState } from '../modules/merchant-selection/types/merchant-selection-state.functions'
import { createConsumerPostSubmissionState } from '../modules/consumer-post-submission/types/consumer-post-submission-state.function'
import { createPreQualifierLoadingProductState } from '../modules/pre-qualifier-loading-product/types/pre-qualifier-loading-product-state.functions'
import { createAssetSubtypeState } from '../modules/asset-subtype-selection/types/asset-subtype-selection-state.functions'
import { createGeoLocationStateState } from '../modules/geo-location/types/geo-location-tab-state.functions'
import { createOpportunitySubscriptionState } from '../modules/opportunity-subscription/types/opportunity-subscription-state.functions'

export function createConsumerState(): ConsumerState {
  return {
    consumerWorkflowState: createConsumerWorkflowState(),
    consumerProfileState: createConsumerProfileState(),
    consumerSessionState: createConsumerSessionState(),
    geoLocationState: createGeoLocationStateState(),
    merchantSelectionState: createMerchantSelectionState(),
    assetSubtypeState:
      createAssetSubtypeState(),
    preQualifierAssetTypeSelectionState:
      createPreQualifierAssetTypeSelectionState(),
    preQualifierLoadingProductState: createPreQualifierLoadingProductState(),
    securityQuestionsState: createSecurityQuestionsState(),
    consumerPostSubmissionState: createConsumerPostSubmissionState(),
    offerUnsubscribeState: createOpportunitySubscriptionState()
  }
}
