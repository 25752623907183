import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { SettingsStore } from '../../../modules/app/modules/settings/store/settings.store'
import { ConsumerProfileStore } from '../../../modules/consumer/modules/consumer-profile/store/consumer-profile.store'
import { PreQualifierAssetTypeSelectionStore } from '../../../modules/consumer/modules/pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.store'
import { PreQualifierLoadingProductStore } from '../../../modules/consumer/modules/pre-qualifier-loading-product/store/pre-qualifier-loading-product.store'
import { Paragraph } from '../../../ui-library/components/paragraph/paragraph.component'
import { ShowIfFragment } from '../../../ui-library/components/show-if/show-if-fragment.component'
import { withShowIf } from '../../../ui-library/helpers/with-show-if/with-show-if.component'
import { Footer } from '../main-layout/components/footer/footer.component'
import { MoreInfo } from '../more-info/more-info.component'
import { AssetTypeDetails } from './components/asset-type-details/asset-type-details.component'
import { ProductDetails } from './components/product-details/product-details.component'
import {
  getLocalizedAssetName,
  isAssetTitleValid
} from './consumer-profile-asset-image-container.functions'
import { ConsumerProfileAssetImageContainerProps } from './consumer-profile-asset-image-container.props'
import { ConsumerProfileAssetImageContainerStyles } from './consumer-profile-asset-image-container.styles'

export const ConsumerProfileAssetImageContainer = withShowIf(
  (props: ConsumerProfileAssetImageContainerProps) => {
    const locale = useSelector(SettingsStore.getLocaleSetting)
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState<boolean>(false)
    const consumerProfileAssetImage =
      useSelector(ConsumerProfileStore.getConsumerProfileAssetTypeImageUrl) ??
      ''
    const consumerProfileAssetName = useSelector(
      ConsumerProfileStore.getConsumerProfileAssetTypeImageTitle
    ) ?? { en: '', fr: '' }
    const preQualifierProductDetails = useSelector(
      PreQualifierLoadingProductStore.getProductDetails
    )
    const consumerProfileAsset = useSelector(
      ConsumerProfileStore.getConsumerProfileAsset
    )
    const consumerProfileVendor = useSelector(ConsumerProfileStore.getVendor)
    const selectedAssetTypeImageTitle = useSelector(
      PreQualifierAssetTypeSelectionStore.getSelectedAssetTypeImageTitle
    )
    const selectedAssetTypeImageUrl = useSelector(
      PreQualifierAssetTypeSelectionStore.getSelectedAssetTypeImageUrl
    )
    const vendor = useSelector(ConsumerProfileStore.getVendor)

    const shouldShowMoreInfo = useMemo(() => {
      return Boolean(props.moreInfoMessage)
    }, [props.moreInfoMessage])

    const handleMoreInfoOpen = () => {
      setMoreInfoModalOpen(true)
    }

    const handleMoreInfoClose = () => {
      setMoreInfoModalOpen(false)
    }

    const shouldShowAssetDetails = useMemo(() => {
      return Boolean(consumerProfileAsset || preQualifierProductDetails)
    }, [consumerProfileAsset, preQualifierProductDetails])

    const assetImage = useMemo(() => {
      if (consumerProfileAssetImage) {
        return consumerProfileAssetImage
      }
      if (selectedAssetTypeImageUrl) {
        return selectedAssetTypeImageUrl
      }
      return ''
    }, [consumerProfileAssetImage, selectedAssetTypeImageUrl])

    const assetName = useMemo(() => {
      if (
        consumerProfileAssetName &&
        isAssetTitleValid(consumerProfileAssetName)
      ) {
        return getLocalizedAssetName(locale, consumerProfileAssetName)
      }
      if (
        selectedAssetTypeImageTitle &&
        isAssetTitleValid(selectedAssetTypeImageTitle)
      ) {
        return getLocalizedAssetName(locale, selectedAssetTypeImageTitle)
      }
      return ''
    }, [locale, consumerProfileAssetName, selectedAssetTypeImageTitle])

    return (
      <div
        className={ConsumerProfileAssetImageContainerStyles.wrapper(
          props.noHorizontalPadding || false
        )}
      >
        <div className={ConsumerProfileAssetImageContainerStyles.container}>
          <div
            className={ConsumerProfileAssetImageContainerStyles.titleContainer}
          >
            {props.titleStatusIcon}
            <Paragraph
              uat={props.titleUat}
              className={clsx(
                props.titleClasses,
                ConsumerProfileAssetImageContainerStyles.title
              )}
            >
              {props.titleMessage}
            </Paragraph>
          </div>
          <ShowIfFragment showIf={Boolean(props.subTitleMessage)}>
            <div
              className={
                ConsumerProfileAssetImageContainerStyles.subtitleContainer
              }
            >
              <Paragraph
                uat={props.warningUat}
                className={ConsumerProfileAssetImageContainerStyles.subTitle}
              >
                {props.warningMessage}
              </Paragraph>
              <Paragraph
                uat={props.subTitleUat}
                className={ConsumerProfileAssetImageContainerStyles.subTitle}
              >
                {props.subTitleMessage}
              </Paragraph>
              <MoreInfo
                showIf={shouldShowMoreInfo}
                open={moreInfoModalOpen}
                onOpen={handleMoreInfoOpen}
                onClose={handleMoreInfoClose}
                message={props.moreInfoMessage || ''}
              />
            </div>
          </ShowIfFragment>
          {props.children}
		      <Paragraph uat={props.disclaimerUat} className={ConsumerProfileAssetImageContainerStyles.disclaimer}>
                {props.disclaimerMessage}
          </Paragraph>
          <Footer hideIf={!Boolean(props.showFooter)} />
        </div>
        <ProductDetails
          showIf={shouldShowAssetDetails}
          productDetails={preQualifierProductDetails}
          consumerProfileAsset={consumerProfileAsset}
          consumerProfileVendor={consumerProfileVendor}
        />

        <AssetTypeDetails
          showIf={!shouldShowAssetDetails}
          assetTypeImageUrl={assetImage}
          assetTypeName={assetName}
          assetTypeImageUat={props.assetImageUat}
          vendor={vendor}
        />
      </div>
    )
  }
)
