import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../store/types/root-state/root-state.interface';
import { OpportunitySubscriptionState } from '../types/opportunity-subscription-state.interface';
import { HttpError } from '../../../../../shared/types/http-error.interface';
import { RootStore } from '../../../../../store/root.store';
import { OpportunitySubscriptionActions } from './opportunity-subscription.actions';

const getOfferUnsubscribeState: Selector<
  RootState,
  OpportunitySubscriptionState | undefined
> = createSelector(
  (state: RootState) => state.consumerState.offerUnsubscribeState,
  (state?: OpportunitySubscriptionState) => state,
)

const getIsLoading: Selector<RootState, boolean> = createSelector(
  getOfferUnsubscribeState,
  (state?: OpportunitySubscriptionState) => state?.isLoading ?? false,
);

const getUnsubscribeError: Selector<RootState, HttpError | null> = createSelector(
  getOfferUnsubscribeState,
  (state?: OpportunitySubscriptionState) => state?.unsubscribeError ?? null,
);

const getUnsubscribeSuccess: Selector<RootState, boolean | null> = createSelector(
  getOfferUnsubscribeState,
  (state?: OpportunitySubscriptionState) => state?.unsubscribeSuccess ?? null,
);

const unsubscribeEmail = (email: string) =>
  RootStore.dispatch(OpportunitySubscriptionActions.unsubscribeEmail(email))

export const OpportunitySubscriptionStore = {
  getOfferUnsubscribeState,
  getIsLoading,
  getUnsubscribeError,
  getUnsubscribeSuccess,
  unsubscribeEmail,
}
