import { consumerProfileEpics } from '../modules/consumer-profile/store/consumer-profile.epics'
import { consumerWorkflowEpics } from '../modules/consumer-workflow/store/consumer-workflow.epics'
import { consumerSessionEpics } from '../modules/consumer-session/store/consumer-session.epics'
import { preQualifierAssetTypeSelectionEpics } from '../modules/pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.epics'
import { merchantSelectionEpics } from '../modules/merchant-selection/store/merchant-selection.epics'
import { securityQuestionsEpics } from '../modules/consumer-authentication/modules/security-questions/store/security-questions.epics'
import { consumerPostSubmissionEpics } from '../modules/consumer-post-submission/store/consumer-post-submission.epics'
import { preQualifierLoadingProductEpics } from '../modules/pre-qualifier-loading-product/store/pre-qualifier-loading-product.epics'
import { assetSubtypeEpics } from '../modules/asset-subtype-selection/store/asset-subtype-selection.epics'
import { geoLocationEpics } from '../modules/geo-location/store/geo-location.epics'
import { opportunitySubscriptionEpics } from '../modules/opportunity-subscription/store/opportunity-subscription.epics'

export const consumerEpics = [
  ...consumerWorkflowEpics,
  ...consumerProfileEpics,
  ...consumerSessionEpics,
  ...merchantSelectionEpics,
  ...securityQuestionsEpics,
  ...assetSubtypeEpics,
  ...preQualifierAssetTypeSelectionEpics,
  ...preQualifierLoadingProductEpics,
  ...consumerPostSubmissionEpics,
  ...geoLocationEpics,
  ...opportunitySubscriptionEpics
]
